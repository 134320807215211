export const WORKSPACE_FETCH_URL = "https://sdk.hackle.io/api/v2/w/workspaces"

export const WORKSPACE_FETCH_URL_PREFIX = "https://sdk.hackle.io/api/v2/w/"

export const WORKSPACE_FETCH_URL_POSTFIX = "/w"

export const EVENT_DISPATCH_URL = "https://event.hackle.io/api/v2/w/events"

export const EVENT_DISPATCH_URL_PREFIX = "https://event.hackle.io/api/v2/w/"

export const EVENT_DISPATCH_URL_POSTFIX = "/events"

export const SDK_KEY_HEADER = "X-HACKLE-SDK-KEY"

export const SDK_NAME_HEADER = "X-HACKLE-SDK-NAME"

export const SDK_VERSION_HEADER = "X-HACKLE-SDK-VERSION"

export const SDK_TIME_HEADER = "X-HACKLE-SDK-TIME"

export const SDK_VERSION = "3.4.1"

export const DEFAULT_POOL_INTERVAL = 10000

export const REQUEST_TIME_OUT = 10000

export const BATCH_SIZE = 100

export const DEFAULT_FLUSH_INTERVAL = 500 // milliseconds

export const ERROR_RETRY_BASE_WAIT_SECONDS_BY_ERROR_COUNT = [0, 8, 16, 32, 64, 128, 256, 512]

export const COOKIE_EXPIRE_DAYS = 365 * 10

export const COOKIE_HID_KEY = "_hackle_hid"

export const COOKIE_USER_ID_KEY = "_hackle_id"
